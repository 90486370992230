import type { FetchResponse } from 'ofetch';
import type {
  ResponseSuccess,
  ResponseSuccessWithData,
  ResponseError,
} from '~/data/services/types/response.type';
import type {
  AuthBasicResponse,
  AuthChangePasswordPayload,
  AuthConfirmRegistrationPayload,
  AuthLoginPayload,
  AuthResetPasswordPayload,
} from '~/data/services/types/auth.type';
import type { CustomFetch, CustomFetchOptions } from '~/shared/types/custom-fetch.type';
import type { BackendUserModel } from '~/data/services/types/user.type';

export interface GoogleCallbackPayload {
  authuser: string;
  code: string;
  prompt: string;
  scope: string;
}

export const AuthService = (fetch: CustomFetch) => {
  async function login(payload: AuthLoginPayload): Promise<AuthBasicResponse> {
    return await fetch('api/auth/login', {
      method: 'POST',
      credentials: 'include',
      localError: true,
      body: payload,
    });
  }

  async function logout(): Promise<void> {
    return await fetch('api/auth/logout', {
      method: 'POST',
      credentials: 'include',
    });
  }

  async function signUp(payload: AuthLoginPayload): Promise<AuthBasicResponse> {
    return await fetch('api/auth/registration', {
      method: 'POST',
      credentials: 'include',
      localError: true,
      body: payload,
    });
  }

  async function confirmRegistration(
    payload: AuthConfirmRegistrationPayload,
  ): Promise<AuthBasicResponse> {
    return await fetch('api/auth/confirm-registration', {
      method: 'POST',
      credentials: 'include',
      localError: true,
      body: payload,
    });
  }

  async function resendVerificationEmail(payload: { email: string }): Promise<void> {
    return await fetch('api/auth/resend-message', {
      method: 'POST',
      body: payload,
    });
  }

  // Overload signatures for `refresh`
  async function refresh(
    options: CustomFetchOptions,
    returnError: true,
  ): Promise<FetchResponse<ResponseSuccessWithData<AuthBasicResponse> | ResponseError>>;

  async function refresh(
    options: CustomFetchOptions,
  ): Promise<
    AuthBasicResponse | FetchResponse<ResponseSuccessWithData<AuthBasicResponse> | ResponseError>
  > {
    const response = await fetch('api/auth/token/refresh', {
      method: 'POST',
      credentials: 'include',
      ...options,
    });

    // Handle the case where returnError is true
    if ((response as FetchResponse<ResponseError>)?._data?.success === false) {
      return response as FetchResponse<ResponseError>;
    } else {
      // Wrap successful response in ResponseSuccessWithData if returnError is true
      return response as FetchResponse<ResponseSuccessWithData<AuthBasicResponse>>;
    }
  }

  async function resetPassword(payload: AuthResetPasswordPayload): Promise<ResponseSuccess> {
    return await fetch('api/auth/reset-password', {
      method: 'POST',
      body: payload,
      localError: true,
    });
  }

  async function changePassword(payload: AuthChangePasswordPayload): Promise<ResponseSuccess> {
    return await fetch('api/auth/new-password', {
      method: 'POST',
      body: payload,
      localError: true,
    });
  }

  // Overload signatures for `getMe`
  async function getMe(options?: CustomFetchOptions): Promise<BackendUserModel>;
  async function getMe(
    options?: CustomFetchOptions,
    returnError?: boolean,
  ): Promise<FetchResponse<ResponseSuccessWithData<BackendUserModel> | ResponseError>>;

  async function getMe(
    options?: CustomFetchOptions,
    returnError = false,
  ): Promise<
    BackendUserModel | FetchResponse<ResponseSuccessWithData<BackendUserModel> | ResponseError>
  > {
    const response = await fetch('api/identify/me', {
      method: 'GET',
      ...options,
    });

    if (returnError) {
      // Check if it's an error response or a success response
      const errorResponse = response as FetchResponse<ResponseError>;
      const successResponse = response as FetchResponse<ResponseSuccessWithData<BackendUserModel>>;

      if (errorResponse?._data?.success === false) {
        return errorResponse;
      } else {
        return successResponse;
      }
    } else {
      // Directly return the BackendUserModel
      return response as BackendUserModel;
    }
  }

  async function google(): Promise<string> {
    return await fetch('api/auth/google', {
      method: 'GET',
      localError: true,
    });
  }

  async function googleCallback(payload: GoogleCallbackPayload): Promise<AuthBasicResponse> {
    const queryParams = new URLSearchParams(
      payload as unknown as Record<string, string>,
    ).toString();

    return await fetch(`api/auth/google/callback?${queryParams}`, {
      credentials: 'include',
      method: 'GET',
    });
  }

  return {
    login,
    logout,
    signUp,
    confirmRegistration,
    resendVerificationEmail,
    refresh,
    resetPassword,
    changePassword,
    getMe,
    google,
    googleCallback,
  };
};
