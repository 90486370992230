// import { AppLocale } from '../domain/app.locale';
import { AppCurrency } from './currency';

export const DE_EMAIL = 'inbox@dapp.expert';
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_LOCALE_ID = 1;
export const DEFAULT_CURRENCY = AppCurrency.USD;
export const DEFAULT_CURRENCY_ID = 1;
export const DEFAULT_LOCALE_NAME_COOKIE = 'de_locale';

// note: language_id & currency_id cookie names is what backend expected
export const DEFAULT_LOCALE_ID_COOKIE = 'language_id';
export const DEFAULT_CURRENCY_ID_COOKIE = 'currency_id';
export const DEFAULT_CURRENCY_SHORT_NAME_COOKIE = 'de_currency_short_name';
export const COOKIE_CONSENT_TOKEN = 'de_cookie_consent';

export const PASSWORD_STRENGTH_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/;
export const WEBSITE_URL_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(:[0-9]{1,5})?(\/[^\s]*)?$/;
export const WALLET_CRYPTOS = [
  'ETH',
  'BNB',
  'TRX',
  'SOL',
  'MATIC',
  'AVAX',
  'FTM',
  'CRO',
  'KLAY',
  'AURORA',
];
