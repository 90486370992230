export enum AppCurrency {
  USD = 'USD',
  UAH = 'UAH',
  JPY = 'JPY',
  BTC = 'BTC',
  EUR = 'EUR',
  RUB = 'RUB',
  CNY = 'CNY',
  ETH = 'ETH',
  GBR = 'GBR',
  CHF = 'CHF',
  Fantic = 'Fantic',
}

export const CURRENCIES = [
  {
    label: 'United States Dollar',
    code: AppCurrency.USD,
  },
  {
    label: 'Ukrainian Hryvnia',
    code: AppCurrency.UAH,
  },
  {
    label: 'Japanese Yen',
    code: AppCurrency.JPY,
  },
  {
    label: 'Bitcoin',
    code: AppCurrency.BTC,
  },
  {
    label: 'Euro',
    code: AppCurrency.EUR,
  },
  {
    label: 'Russian Ruble',
    code: AppCurrency.RUB,
  },
  {
    label: 'Chinese Yuan',
    code: AppCurrency.CNY,
  },
  {
    label: 'Ethereum',
    code: AppCurrency.ETH,
  },
  {
    label: 'Pound Sterling',
    code: AppCurrency.GBR,
  },
  {
    label: 'Swiss Franc',
    code: AppCurrency.CHF,
  },
  {
    label: 'Fantic',
    code: AppCurrency.Fantic,
  },
];
