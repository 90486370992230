import { useNuxtApp } from '#app';
import { ref } from 'vue';
import type { Ref } from 'vue';
import type { ErrorCode } from '~/data/domain/response/errors/error-codes';
import { errorMessages } from '~/data/domain/response/errors/error.messages';
import { errorCodes } from '~/data/domain/response/errors/error-codes';

export const useErrorHandling = (customErrorMessages: Record<number | string, string> = {}) => {
  const { $i18n } = useNuxtApp();

  const messages: Ref<Partial<Record<ErrorCode, string>>> = ref({
    ...errorMessages,
    ...customErrorMessages,
  });

  const isErrorCode = (code: string | number): code is ErrorCode => {
    const errorCodes = Object.keys(messages.value);
    const parsedCode = String(code);
    const allowedKeys: string[] = Object.values(errorCodes);

    return allowedKeys.includes(parsedCode);
  };

  const getErrorMessage = (errors: string | string[] = '', fallbackMessage?: string) => {
    let errorCode;

    if (Array.isArray(errors)) {
      errorCode = errors[0];
    } else {
      errorCode = errors;
    }

    const parsedCode = String(errorCode);

    if (!isErrorCode(parsedCode)) {
      return fallbackMessage ?? $i18n.t(messages.value[errorCodes.E_000] ?? 'Unknown error');
    }

    return $i18n.t(messages.value[parsedCode] ?? 'Unknown error');
  };

  return {
    getErrorMessage,
  };
};
