export type ValueOf<T> = T[keyof T];

const E_000 = '000';
const E_400 = '400';
const E_401 = '401';
const E_403 = '403';
const E_404 = '404';
const E_409 = '409';
const E_412 = '412';
const E_429 = '429';
const E_500 = '500';

const E_CREDENTIALS_INCORRECT = 'credentials_incorrect';
const E_EMAIL_NOT_CONFIRMED = 'email_not_confirmed';
const E_EMAIL_ALREADY_REGISTERED = 'email_has_been_taken';
const E_INVALID_CODE = 'invalid_code';
const E_INCORRECT_CODE = 'code_is_not_correct';
const E_WALLET_ADDRESS_NOT_FOUND = 'wallet_address_not_found';
const E_REFRESH_TOKEN_NOT_FOUND = 'refresh_token_not_found';
const E_NEWS_NOT_FOUND = 'news_not_found';
const E_DAPP_NOT_FOUND = 'dapp_not_found';
const E_WALLET_ADDRESS_TAKEN = 'wallet_address_has_been_taken';
const E_EMAIL_CONFIRMATION_REQUIRED = 'old_user_mail_confirmation';

export const errorCodes = {
  E_000,
  E_400,
  E_401,
  E_403,
  E_404,
  E_409,
  E_412,
  E_429,
  E_500,
  E_CREDENTIALS_INCORRECT,
  E_EMAIL_NOT_CONFIRMED,
  E_EMAIL_ALREADY_REGISTERED,
  E_INVALID_CODE,
  E_WALLET_ADDRESS_NOT_FOUND,
  E_REFRESH_TOKEN_NOT_FOUND,
  E_NEWS_NOT_FOUND,
  E_WALLET_ADDRESS_TAKEN,
  E_INCORRECT_CODE,
  E_DAPP_NOT_FOUND,
  E_EMAIL_CONFIRMATION_REQUIRED,
} as const;

export type ErrorCode = ValueOf<typeof errorCodes>;
