<template>
  <nuxt-layout name="default">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-flex-grow tw-py-10"
    >
      <div class="tw-mb-15">
        <template v-if="error?.statusCode === 404">
          <img src="~/assets/gifs/404.gif" alt="404" />
          <p class="heading-h4.1 tw-text-center">{{ t('errors.errorPage404') }}</p>
        </template>
        <template v-else>
          {{ error?.statusCode }}
          {{ error?.message }}
        </template>
      </div>

      <de-button
        :category="ButtonCategoryOptions.secondary"
        :variant="ButtonVariantOptions.confirm"
        :size="ButtonSizeOptions.medium"
        :label="t('common.buttons.backToX', { field: 'common.home' })"
        @click="clearError({ redirect: '/' })"
      />
    </div>
  </nuxt-layout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import { useI18n } from 'vue-i18n';
import { clearError } from '#app';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import {
  ButtonCategoryOptions,
  ButtonSizeOptions,
  ButtonVariantOptions,
} from '~/shared/components/lib/button/button.entity';

defineProps({
  error: Object as () => NuxtError,
});

const { t } = useI18n();
</script>
