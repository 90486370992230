<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <VueQueryDevtools />
</template>

<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { useHead } from '#imports';

useHead({
  noscript: [
    {
      innerHTML: `
        <div>
          <img
            src="https://mc.yandex.ru/watch/86877417"
            style="position: absolute; left: -9999px"
            alt=""
          />
        </div>
      `,
    },
  ],
});
</script>
