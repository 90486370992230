export enum ButtonCategoryOptions {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export enum ButtonVariantOptions {
  default = 'default',
  confirm = 'confirm',
  link = 'link',
  text = 'text',
}

export enum ButtonSizeOptions {
  normal = 'normal',
  medium = 'md',
}

export enum ButtonIconPositionOptions {
  left = 'left',
  right = 'right',
  center = 'center',
}
