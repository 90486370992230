import DOMPurify from 'isomorphic-dompurify';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('safe-html', {
    // Client-side DOM manipulation (mounted)
    mounted(el, binding) {
      el.innerHTML = DOMPurify.sanitize(binding.value);
    },

    // Server-side rendering (SSR)
    getSSRProps(binding) {
      return {
        // works only in vue 3.5.4, so nuxt needed to be updated for it to work
        innerHTML: DOMPurify.sanitize(binding.value),
      };
    },
  });
});
