import type { ToastServiceMethods } from 'primevue/toastservice';
import type { ToastMessageOptions } from 'primevue/toast';
import type { SvgIcons } from 'assets/icons/svg-icons';

export interface CustomToastMessageOptions extends ToastMessageOptions {
  iconName: SvgIcons | undefined;
  iconClass: string;
}

export interface CustomToastInstance extends ToastServiceMethods {
  add(message: CustomToastMessageOptions): void;
}

interface ToastNotificationOptions {
  title?: string;
  body: string;
  lifeTime?: number;
  closable?: boolean;
  iconName?: SvgIcons | undefined;
  iconClass?: string;
}

const defaultLifeTime = 3000;

export function toastErrorNotification(
  toastInstance: CustomToastInstance,
  {
    title = '',
    body = '',
    lifeTime = defaultLifeTime,
    closable = false,
    iconName,
    iconClass = '',
  }: ToastNotificationOptions,
): void {
  toastInstance.add({
    severity: 'error',
    summary: title,
    detail: body,
    life: lifeTime,
    closable,
    iconName,
    iconClass,
  });
}

export function toastSuccessNotification(
  toastInstance: CustomToastInstance,
  {
    title = '',
    body = '',
    lifeTime = defaultLifeTime,
    closable = false,
    iconName = 'check',
    iconClass = 'tw-w-350 tw-h-350 tw-text-success-500',
  }: ToastNotificationOptions,
): void {
  toastInstance.add({
    severity: 'success',
    summary: title,
    detail: body,
    life: lifeTime,
    closable,
    iconName,
    iconClass,
  });
}

export function toastInfoNotification(
  toastInstance: CustomToastInstance,
  {
    title = '',
    body = '',
    lifeTime = defaultLifeTime,
    closable = false,
    iconName,
    iconClass = '',
  }: ToastNotificationOptions,
): void {
  toastInstance.add({
    severity: 'info',
    summary: title,
    detail: body,
    life: lifeTime,
    closable,
    iconName,
    iconClass,
  });
}
