import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPrimeCalendar, LazyPrimeCheckbox, LazyPrimeDropdown, LazyPrimeInputNumber, LazyPrimeInputSwitch, LazyPrimeInputText, LazyPrimePassword, LazyPrimeSelectButton, LazyPrimeTextarea, LazyPrimeButton, LazyPrimeColumn, LazyPrimeDataTable, LazyPrimePaginator, LazyPrimeAccordion, LazyPrimeAccordionTab, LazyPrimeDeferredContent, LazyPrimeDialog, LazyPrimeOverlayPanel, LazyPrimeSidebar, LazyPrimeFileUpload, LazyPrimeMenu, LazyPrimeTabMenu, LazyPrimeToast, LazyPrimeProgressSpinner } from '#components'
const lazyGlobalComponents = [
  ["PrimeCalendar", LazyPrimeCalendar],
["PrimeCheckbox", LazyPrimeCheckbox],
["PrimeDropdown", LazyPrimeDropdown],
["PrimeInputNumber", LazyPrimeInputNumber],
["PrimeInputSwitch", LazyPrimeInputSwitch],
["PrimeInputText", LazyPrimeInputText],
["PrimePassword", LazyPrimePassword],
["PrimeSelectButton", LazyPrimeSelectButton],
["PrimeTextarea", LazyPrimeTextarea],
["PrimeButton", LazyPrimeButton],
["PrimeColumn", LazyPrimeColumn],
["PrimeDataTable", LazyPrimeDataTable],
["PrimePaginator", LazyPrimePaginator],
["PrimeAccordion", LazyPrimeAccordion],
["PrimeAccordionTab", LazyPrimeAccordionTab],
["PrimeDeferredContent", LazyPrimeDeferredContent],
["PrimeDialog", LazyPrimeDialog],
["PrimeOverlayPanel", LazyPrimeOverlayPanel],
["PrimeSidebar", LazyPrimeSidebar],
["PrimeFileUpload", LazyPrimeFileUpload],
["PrimeMenu", LazyPrimeMenu],
["PrimeTabMenu", LazyPrimeTabMenu],
["PrimeToast", LazyPrimeToast],
["PrimeProgressSpinner", LazyPrimeProgressSpinner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
