import { useRuntimeConfig, createError, useRequestHeaders } from '#app';
import type { NuxtError } from '#app';
import { useToast } from 'primevue/usetoast';
import type { FetchResponse } from 'ofetch';
import { useRouter } from 'vue-router';
import type { NitroFetchRequest } from 'nitropack';
import { defineNuxtPlugin } from '#imports';
import { useAuthStore } from '~/store/auth';
import { toastErrorNotification } from '~/utils/toast-notification-helper';
import type { ResponseSuccessWithData, ResponseError } from '~/data/services/types/response.type';
import { useErrorHandling } from '~/shared/composable/useErrorHandling';
import type {
  AppResponseErrorData,
  CustomFetch,
  CustomFetchOptions,
} from '~/shared/types/custom-fetch.type';
import type { AuthBasicResponse } from '~/data/services/types/auth.type';
import { errorCodes } from '~/data/domain/response/errors/error-codes';
import { useUserStore } from '~/store/user';
import { AuthService } from '~/data/services/auth.service';
import { parseAndSetCookies } from '~/utils/set-cookies';
import type { BackendUserModel } from '~/data/services/types/user.type';
import { createUserModelBase } from '~/data/model/user.model';

function setBaseHeaders(options?: CustomFetchOptions) {
  const reqHeaders = useRequestHeaders(['cookie', 'referer']);
  let passedHeaders = { ...reqHeaders };

  if (options?.headers) {
    passedHeaders = {
      ...passedHeaders,
      ...options.headers,
    };
  }

  const headers = new Headers(passedHeaders);
  headers.set('Accept', 'application/json');

  return headers;
}

function getError(
  response: FetchResponse<ResponseError>,
  request: NitroFetchRequest,
  options?: CustomFetchOptions,
): NuxtError<AppResponseErrorData> {
  const message = Array.isArray(response._data?.errorCode)
    ? response._data?.errorCode?.[0]
    : response._data?.errorCode;

  const statusCode = message?.includes('not_found') ? 404 : response.status;

  return createError({
    statusCode,
    message,
    fatal: statusCode === 404,
    data: {
      request,
      method: options?.method ?? null,
      params: options?.params ?? null,
      body: options?.body ?? null,
      response: response._data || null,
    },
  });
}

export type RawFetchFunction = <T = any>(
  url: string,
  options?: RequestInit,
) => Promise<FetchResponse<T>>;

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const toast = useToast();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const { getErrorMessage } = useErrorHandling();
  const router = useRouter();

  async function loginUser(data: AuthBasicResponse): Promise<void> {
    authStore.setToken(data.access_token);

    const response = await authRepo.getMe(
      {
        headers: { Authorization: `Bearer ${data.access_token}` },
      },
      true,
    );

    if (response._data?.success)
      userStore.setUser(createUserModelBase(response._data?.data as BackendUserModel));
  }

  function logoutUser() {
    authStore.setToken(null);
    userStore.clearUser();
  }

  const instance = $fetch.create({
    baseURL: config.public.baseURL,
  });
  const authRepo = AuthService(instance.raw);
  let refreshTokenRequest: Promise<
    FetchResponse<ResponseSuccessWithData<AuthBasicResponse> | ResponseError>
  > | null = null;

  const customFetch: CustomFetch = async (request, options) => {
    const headers = setBaseHeaders(options);

    if (authStore.accessToken) {
      headers.set('Authorization', `Bearer ${authStore.accessToken}`);
    }

    try {
      const response: FetchResponse<ResponseSuccessWithData<any> | ResponseError> =
        await instance.raw(request, { headers, ...options });

      // if api response status code is ok, but api returns success:false
      if (response._data && response._data?.success === false) {
        const data: ResponseError = response._data;

        if (options?.localError) throw data;

        if (import.meta.client) {
          if (data.errorCode?.length) {
            toastErrorNotification(toast, {
              body: getErrorMessage(data.errorCode),
            });
          }
        }

        throw getError(response as FetchResponse<ResponseError>, request, options);
      }

      const cookies = response.headers.getSetCookie();

      if (nuxtApp.ssrContext?.event) {
        parseAndSetCookies(cookies, nuxtApp.ssrContext.event);
      }

      return response._data?.data !== undefined ? response._data.data : response._data;
    } catch (error: any) {
      if (error.response?.status === 401) {
        // when multiple simultaneous requests fail with 401 error => send only one refresh request, wait until it resolves and repeat multiple failed request;
        if (!refreshTokenRequest) {
          refreshTokenRequest = authRepo
            .refresh({ headers }, true)
            .then(async (refreshResponse) => {
              if (refreshResponse._data?.success) {
                await loginUser(refreshResponse._data.data);

                return refreshResponse;
              } else if (
                (refreshResponse as FetchResponse<ResponseError>)._data?.errorCode.includes(
                  errorCodes.E_REFRESH_TOKEN_NOT_FOUND,
                )
              ) {
                if (import.meta.client) {
                  toastErrorNotification(toast, {
                    body: 'Your session is expired. Please log in',
                  });
                }

                logoutUser();
                await router.replace('/');
                throw new Error('refresh token not found');
              } else {
                throw new Error(`RefreshAccessTokenError: ${refreshResponse._data?.errorCode}`);
              }
            })
            .finally(() => {
              refreshTokenRequest = null;
            });
        }

        // Wait for the refresh token request to complete and retry the original request
        await refreshTokenRequest;

        return customFetch(request, options);
      }

      console.error(error);
      throw error;
    }
  };

  const rawFetch = instance.raw;

  return {
    provide: {
      customFetch,
      rawFetch,
    },
  };
});
