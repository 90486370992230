import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useCookie, useRuntimeConfig } from '#app';
import { createUserModelBase } from '~/data/model/user.model';
import type { UserProfileModel, UserModelBase } from '~/data/model/user.model';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_ID_COOKIE,
  DEFAULT_CURRENCY_ID,
  DEFAULT_CURRENCY_SHORT_NAME_COOKIE,
} from '~/data/domain/constants';
import type { AppCurrency } from '~/data/domain/currency';
import type { WalletProvider } from '~/data/domain/wallet';
import { USER_AVATAR_STUMP_PATH } from '~/shared/constants/static-images-paths.constant';
import { CURRENCY_COOKIE_DURATION_DAYS } from '~/shared/constants/cookie-duration';

export const useUserStore = defineStore('user', () => {
  const config = useRuntimeConfig();

  const currencyIdCookie = useCookie(DEFAULT_CURRENCY_ID_COOKIE, {
    default: () => DEFAULT_CURRENCY_ID,
    maxAge: CURRENCY_COOKIE_DURATION_DAYS * 24 * 60 * 60,
    domain: config.public.rootDomain as string,
  });

  const currencyShortNameCookie = useCookie(DEFAULT_CURRENCY_SHORT_NAME_COOKIE, {
    default: () => DEFAULT_CURRENCY,
    maxAge: CURRENCY_COOKIE_DURATION_DAYS * 24 * 60 * 60,
  });

  const defaultUserState: UserModelBase = createUserModelBase({
    id: null,
    username: null,
    email: null,
    currency_id: currencyIdCookie.value,
    currency_short_name: currencyShortNameCookie.value,
    user_wallet: {
      wallet_address: null,
      method: null,
    },
  });

  const user = ref({
    ...defaultUserState,
  });

  watch(
    () => user.value.currencyId,
    (val) => {
      currencyIdCookie.value = val;
    },
  );

  watch(
    () => user.value.currencyShortName,
    (val) => {
      currencyShortNameCookie.value = val;
    },
  );

  function setUser(data: UserModelBase) {
    user.value = data;
  }

  function updateUserProfile(newValues: UserProfileModel) {
    user.value.profile = {
      ...user.value.profile,
      ...newValues,
    };
  }

  function setUserEmail(email: string) {
    user.value.email = email;
  }

  function setUserWallet(address: string | null, type: WalletProvider | null) {
    user.value.wallet.address = address;
    user.value.wallet.type = type;
  }

  function clearUser() {
    user.value = defaultUserState;
  }

  function updateUserPhoto(newPhoto: string | null) {
    user.value.profile.photo = newPhoto ?? USER_AVATAR_STUMP_PATH;
  }

  function updateUserCurrency(currencyId: number, currencyShortName: AppCurrency) {
    user.value.currencyId = currencyId;
    user.value.currencyShortName = currencyShortName;
  }

  return {
    user,
    setUser,
    clearUser,
    updateUserPhoto,
    setUserEmail,
    updateUserCurrency,
    setUserWallet,
    updateUserProfile,
    currencyShortNameCookie,
    currencyIdCookie,
  };
});
