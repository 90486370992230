<template>
  <prime-button
    :label="label"
    :disabled="isButtonDisabled"
    :type="type"
    :loading="loading"
    :link="link"
    :pt="{
      root: { class: buttonClasses.root },
    }"
    :class="$attrs.class"
    @click="$emit('click')"
  >
    <de-svg-icon v-if="icon" :name="icon" :class="buttonClasses.icon" />

    <de-svg-icon v-if="loading" name="loader" :class="buttonClasses.loadingIcon" />

    <span v-if="label" :class="buttonClasses.label">{{ label }}</span>
    <slot v-else />
  </prime-button>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { ButtonPassThroughOptions } from 'primevue/button';
import type { SetFieldType } from 'type-fest';
// import { isSlotEmpty } from '~/utils/is-slot-empty';
import { computed } from '#imports';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import type { SvgIcons } from 'assets/icons/svg-icons';
import {
  ButtonCategoryOptions,
  ButtonIconPositionOptions,
  ButtonSizeOptions,
  ButtonVariantOptions,
} from '~/shared/components/lib/button/button.entity';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  category: {
    type: String as PropType<ButtonCategoryOptions>,
    default: ButtonCategoryOptions.primary,
    validator: (value: string) =>
      Object.values(ButtonCategoryOptions).includes(value as ButtonCategoryOptions),
  },
  variant: {
    type: String as PropType<ButtonVariantOptions>,
    default: ButtonVariantOptions.default,
    validator: (value: string) =>
      Object.values(ButtonVariantOptions).includes(value as ButtonVariantOptions),
  },
  size: {
    type: String as PropType<ButtonSizeOptions>,
    default: ButtonSizeOptions.normal,
    validator: (value: string) =>
      Object.values(ButtonSizeOptions).includes(value as ButtonSizeOptions),
  },
  icon: {
    type: String as PropType<SvgIcons | ''>,
    default: '',
  },
  iconPos: {
    type: String as PropType<ButtonIconPositionOptions>,
    default: ButtonIconPositionOptions.left,
    validator: (value: string) =>
      Object.values(ButtonIconPositionOptions).includes(value as ButtonIconPositionOptions),
  },
  iconClass: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
  // selected: {
  //   type: Boolean,
  //   default: false,
  // },
  // buttonTextClasses: {
  //   type: String,
  //   default: '',
  // },
});

defineEmits(['click']);

// const hasIcon = computed(() => {
//   return props.icon !== '';
// });

// const slots = useSlots();
// const hasIconOnly = computed(() => {
//   return isSlotEmpty(slots, 'default') && hasIcon.value;
// });

const isButtonDisabled = computed(() => {
  return props.disabled || props.loading;
});

const buttonClasses = computed(() => {
  const classes: SetFieldType<
    ButtonPassThroughOptions<string>,
    'root' | 'label' | 'icon' | 'loadingIcon',
    string[]
  > = {
    root: ['de-button', `de-button-${props.variant}`],
    label: ['de-button-label'],
    icon: ['de-icon'],
    loadingIcon: ['de-button-icon-loading'],
    badge: [],
  };

  const nonCategoryVariants = [ButtonVariantOptions.link];

  if (
    !nonCategoryVariants.includes(props.variant) &&
    props.category !== ButtonCategoryOptions.primary
  ) {
    classes.root.push(`de-button-${props.variant}-${props.category}`);
  }

  if (props.size) {
    classes.root.push(`de-button-${props.size}`);
  }

  // if (hasIconOnly.value) {
  //   classes.icon.push('de-button-icon-only');
  // }

  if (props.icon) {
    classes.icon.push('de-button-icon', `de-button-icon-${props.iconPos}`, props.iconClass);
  }

  if (props.loading) {
    classes.loadingIcon.push(props.iconClass, `de-button-icon-${props.iconPos}`);
  }

  return classes;
});
</script>
