import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('number-input', {
    mounted(el: HTMLElement) {
      el.addEventListener('input', (event: Event) => {
        const target = event.target as HTMLInputElement;
        let value = target.value;

        // Allow only positive numbers and decimals
        value = value.replace(/[^0-9.]/g, '');

        // Remove leading zeros
        value = value.replace(/^0+(\d)/, '$1');

        // Prevent multiple decimal points
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }

        target.value = value;
        target.dispatchEvent(new Event('input'));
      });
    },
  });
});
