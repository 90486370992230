import payload_ygjqzWS29n from "/builds/dapp.expert/front-nuxt/plugins/payload.ts";
import revive_payload_client_4sVQNw7RlN from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/dapp.expert/front-nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/dapp.expert/front-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/dapp.expert/front-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/dapp.expert/front-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/builds/dapp.expert/front-nuxt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/builds/dapp.expert/front-nuxt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/builds/dapp.expert/front-nuxt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/builds/dapp.expert/front-nuxt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import floating_vue_EIcJ7xiw0h from "/builds/dapp.expert/front-nuxt/.nuxt/floating-vue.mjs";
import plugin_Jozdw60ZsE from "/builds/dapp.expert/front-nuxt/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/builds/dapp.expert/front-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/builds/dapp.expert/front-nuxt/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/dapp.expert/front-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/dapp.expert/front-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import number_input_xUrWT2CU4C from "/builds/dapp.expert/front-nuxt/plugins/directives/number-input.ts";
import safe_html_CjW4kCB3wM from "/builds/dapp.expert/front-nuxt/plugins/directives/safe-html.ts";
import yandex_metrika_client_sr9lvOoyWI from "/builds/dapp.expert/front-nuxt/plugins/analytics/yandex-metrika.client.js";
import google_analytics_client_SfTiWH5tkT from "/builds/dapp.expert/front-nuxt/plugins/analytics/google-analytics.client.js";
import _01_fetch_hkVDifFWlF from "/builds/dapp.expert/front-nuxt/plugins/01.fetch.ts";
import floating_vue_BiOD74u9sH from "/builds/dapp.expert/front-nuxt/plugins/floating-vue.ts";
import vue_i18n_4tPQ9Bsubn from "/builds/dapp.expert/front-nuxt/plugins/vue-i18n.js";
import vue_query_wrmMkNpEpe from "/builds/dapp.expert/front-nuxt/plugins/vue-query.ts";
export default [
  payload_ygjqzWS29n,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  floating_vue_EIcJ7xiw0h,
  plugin_Jozdw60ZsE,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  number_input_xUrWT2CU4C,
  safe_html_CjW4kCB3wM,
  yandex_metrika_client_sr9lvOoyWI,
  google_analytics_client_SfTiWH5tkT,
  _01_fetch_hkVDifFWlF,
  floating_vue_BiOD74u9sH,
  vue_i18n_4tPQ9Bsubn,
  vue_query_wrmMkNpEpe
]