import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useUserStore } from '~/store/user';

export const useAuthStore = defineStore('auth', () => {
  const userStore = useUserStore();

  const isLoggedIn = computed(() => !!userStore.user?.id);

  const accessToken = ref<string | null>(null);

  function setToken(token: string | null) {
    accessToken.value = token;
  }

  return {
    isLoggedIn,
    setToken,
    accessToken,
  };
});
