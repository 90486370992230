export default {
  "default-compact": () => import("/builds/dapp.expert/front-nuxt/layouts/default-compact.vue").then(m => m.default || m),
  default: () => import("/builds/dapp.expert/front-nuxt/layouts/default.vue").then(m => m.default || m),
  "parts-app-contact-us": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/AppContactUs.vue").then(m => m.default || m),
  "parts-app-cookies-notification": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/AppCookiesNotification.vue").then(m => m.default || m),
  "parts-app-disclaimer": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/AppDisclaimer.vue").then(m => m.default || m),
  "parts-app-sidebar": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/AppSidebar.vue").then(m => m.default || m),
  "parts-main-footer": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/MainFooter.vue").then(m => m.default || m),
  "parts-main-header": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/MainHeader.vue").then(m => m.default || m),
  "parts-header-desktop": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/header/HeaderDesktop.vue").then(m => m.default || m),
  "parts-header-mobile": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/header/HeaderMobile.vue").then(m => m.default || m),
  "parts-header-nav-bar": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/header/HeaderNavBar.vue").then(m => m.default || m),
  "parts-header-submit-dialog": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/header/HeaderSubmitDialog.vue").then(m => m.default || m),
  "parts-header-top-bar": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/header/HeaderTopBar.vue").then(m => m.default || m),
  "parts-header-submit-dialog-config": () => import("/builds/dapp.expert/front-nuxt/layouts/parts/header/submit-dialog.config.ts").then(m => m.default || m)
}