import type { BackendUserModel } from '~/data/services/types/user.type';
import { AppCurrency } from '~/data/domain/currency';
import type { WalletProvider } from '~/data/domain/wallet';
import { USER_AVATAR_STUMP_PATH } from '~/shared/constants/static-images-paths.constant';
import { DEFAULT_CURRENCY_ID } from '~/data/domain/constants';

export interface UserProfileModel {
  name: string | null;
  photo: string | null;
  website: string | null;
  bio: string | null;
  birthday: string | null;
}

export interface UserModelBase {
  id: number | null;
  username: string | null;
  email: string | null;
  currencyId: number;
  currencyShortName: AppCurrency;
  wallet: {
    address: string | null;
    type: WalletProvider | null;
  };
  profile: UserProfileModel;

  // newsItemCount: number;
  // articlesItemCount: number;
  // dappsItemCount: number;
}

function createUserModelBase(raw: BackendUserModel): UserModelBase {
  return {
    id: raw.id ?? null,
    username: raw.username ?? null,
    email: raw.email ?? null,
    currencyId: raw.currency_id ?? DEFAULT_CURRENCY_ID,
    currencyShortName: raw.currency_short_name ?? AppCurrency.USD,
    wallet: {
      type: raw.user_wallet?.method ?? null,
      address: raw.user_wallet?.wallet_address ?? null,
    },
    profile: {
      name: raw.profile?.name ?? null,
      photo: raw.profile?.image_url ?? USER_AVATAR_STUMP_PATH,
      website: raw.profile?.website ?? null,
      bio: raw.profile?.bio ?? null,
      birthday: raw.profile?.birthday ?? null,
    },
  };
}

export { createUserModelBase };
