// utils/cookieUtils.ts
import type { CookieOptions } from 'nuxt/app';
import { setCookie } from 'h3';

type CookieSameSite = 'lax' | 'strict' | 'none' | undefined;

export function parseAndSetCookies(cookies: string[], event: any) {
  for (const cookie of cookies) {
    // Split the cookie string into name-value and attributes
    const [nameValue, ...attributes] = cookie.split(';').map((part) => part.trim());

    const [cookieName, cookieValue] = nameValue.split('=');

    const options: CookieOptions = {};

    // Iterate over the attributes to populate options
    for (const attribute of attributes) {
      const [key, value] = attribute.split('=').map((part) => part.trim().toLowerCase());

      switch (key) {
        case 'path':
          options.path = value;
          break;
        case 'samesite':
          options.sameSite = value as CookieSameSite;
          break;
        case 'domain':
          options.domain = value;
          break;
        case 'httponly':
          options.httpOnly = true;
          break;
        case 'secure':
          options.secure = true;
          break;
        case 'max-age':
          options.maxAge = parseInt(value, 10);
          break;
        case 'expires':
          options.expires = new Date(value);
          break;
        default:
          break;
      }
    }

    setCookie(event, cookieName, cookieValue, options);
  }
}
