import { defineNuxtPlugin } from '#app';
import FloatingVue from 'floating-vue';

export default defineNuxtPlugin(() => {
  FloatingVue.options.themes = {
    'info-tooltip': {
      ...FloatingVue.options.themes.tooltip,
      popperTriggers: ['hover'],
      $extend: 'menu',
      $resetCss: true,
    },
  };
});
