import { errorCodes } from './error-codes';
import type { ErrorCode } from './error-codes';

export const errorMessages: Partial<Record<ErrorCode, string>> = {
  [errorCodes.E_000]: 'Something went wrong when making your request. Please try again.',
  // [errorCodes.E_400]: 'We had trouble processing your request',
  // [errorCodes.E_401]: 'You cannot access this while logged out, please log in',
  // [errorCodes.E_403]: 'You are not allowed to access ',
  // [errorCodes.E_404]: "We couldn't find what you were looking for",
  // [errorCodes.E_409]:
  //   'We ran into a conflict, are you trying to create something that already exists?',
  // [errorCodes.E_412]:
  //   'We were not able to make your changes, please get in touch with tech support if this issue persists',
  // [errorCodes.E_429]:
  //   "We've limited how many times you can make this request. Please try again later",
  // [errorCodes.E_500]: 'errors.e500',
  [errorCodes.E_CREDENTIALS_INCORRECT]: 'errors.credentialsIncorrect',
  [errorCodes.E_EMAIL_NOT_CONFIRMED]: 'errors.emailNotConfirmed',
  [errorCodes.E_INVALID_CODE]: 'errors.incorrectCode',
  [errorCodes.E_INCORRECT_CODE]: 'errors.incorrectCode',
  [errorCodes.E_WALLET_ADDRESS_TAKEN]: 'errors.walletAddressTaken',
};
